.app-header {
    padding-left: 8%;
    padding-right: 8%;
    min-height: 50px;
    max-height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--header-color);
    justify-content: space-between;
}

.lithium-container {
    position: relative;
    flex-direction: row;
    align-items: center;
    min-height: 50px;
    max-height: 70px;
    height: 100%;
    width: 3.5em;
    display: inline-block;
    vertical-align: center;
}

.lithium-container > * {
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
}

.button-container {
    position: relative;
}

.user-icon {
    cursor: pointer;
    width: 18px;
    height: 18px;
    transition: fill .2s;
    fill: var(--header-element-color)
}

.dropdown {
    z-index: 120;
    display: none;
    position: absolute;
}

.show {
    display: block;
    width: 100%;
}

.dropdown-button {
    overflow-x: hidden;
}

.header-element {
    color: var(--header-element-color);
    background-color: var(--header-element-bg-color);
    display: flex;
    align-items: center;
    padding-left: .8rem;
    padding-right: .8rem;
    min-height: 50px;
    max-height: 70px;
    border: none;
    outline: none;
    font-family: var(--main-font-family);
    font-size: 1em;
}


.header-element:hover {
    color: var(--header-element-color-hover);
    background-color: var(--header-element-bg-color-hover);
}

.header-element-hover, .header-element-hover:hover {
    color: var(--header-element-color-selected);
    background-color: var(--header-element-bg-color-selected);
}

.dropdown-item {
    float: right;
    border-radius: 0;
    border-bottom: none;
    min-width: 10rem;
}

.dropdown-item:last-child {
    border-radius: 0 0 5px 5px;
    border: 0 solid transparent;
}

.no-hover, .no-hover:hover {
    cursor: default;
    background: var(--button-color);
}

.button-opened {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
}

