.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-text {
    text-align: center;
}

.left {
    display: flex;
    justify-content: left;
}

.height-100 {
    height: 100%;
}

.text {
    color: var(--text-color);
    font-family: var(--main-font-family);
}

.grey-text {
    color: var(--code-color)
}
.horizontal-scroll {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-x: scroll;
}

.horizontal-scroll::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

.gap {
    display: block;
    margin-bottom: 2rem;
}

.small-gap {
    display: block;
    margin-bottom: .5rem;
}

.max-width-500 {
    max-width: 500px;
}

.justify-left {
    justify-content: left;
}

.horizontal {
    display: flex;
    align-items: center;
}

.width-100 {
    width: 100%;
}

.width-50 {
    width: 50%;
}

.hidden {
    visibility: hidden;
}

.horizontal-gap {
    width: 2rem;
}
