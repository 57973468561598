@import url('https://fonts.googleapis.com/css2?family=Lato&family=Manrope:wght@600&family=Noto+Sans+Mono:wght@500&family=Nunito:wght@300&family=Roboto:wght@300&family=Rubik:wght@500&display=swap');

[data-theme="light"] {
    --background-color: #FFFFFF;
    --header-color: #F7F8F9;
    --text-color: #000000;
    --error-text-color: #dc3545;
    --placeholder-color: #8A8A8A;
    --border-color: #E7E7E7;
    --form-background-color: #F7F8F9;
    --form-input-color: #FFFFFF;
    --form-input-border-color: #E7E7E7;
    --form-input-color-hover: #ECECEC;
    --form-input-border-color-hover: #E7E7E7;

    --menu-tab-color: #F7F8F9;
    --menu-tab-hover-color: #E7E7E7;
    --menu-tab-selected-color: #AACDEC;

    --code-color: #687179;
    --code-background-hover-color: #d2d1d1;
    --code-background-color: #E7E7E7;

    --button-color: #007bfe;
    --button-color-hover: #2990fd;
    --button-text-color: #FFFFFF;
    --button-text-color-hover: #FFFFFF;

    --select-color: #F7F8F9;
    --select-hover-color: #E7E7E7;
    --select-selected-color: #AACDEC;

    --header-element-color: #000000;
    --header-element-bg-color: transparent;
    --header-element-color-hover: #000000;
    --header-element-bg-color-hover: #2990fd;
    --header-element-color-selected: #000000;
    --header-element-bg-color-selected: #0465cb;

    --card-color: #FFFFFF;
    --card-border-color: #e5e5e5;
    --card-hover-color: #e5e5e5;

    --icon-color: #000000;
    --icon-hover-color: #6a6a6A;
}

[data-theme="dark"] {
    --background-color: #000C14;
    --header-color: #001A2C;
    --text-color: #F6FFF8;
    --error-text-color: #FF5F3A;
    --placeholder-color: #757575;
    --border-color: #032531;
    --form-background-color: #210124;
    --form-input-color: #001a2c;
    --form-input-border-color: #001a2c;
    --form-input-color-hover: #032531;
    --form-input-border-color-hover: #032531;

    --menu-tab-color: #001a2c;
    --menu-tab-hover-color: #032531;
    --menu-tab-selected-color: #003D66;

    --code-color: #bfc0c0;
    --code-background-hover-color: #001421;
    --code-background-color: #000C14;

    --button-color: #001a2c;
    --button-color-hover: #032531;
    --button-text-color: #F6FFF8;
    --button-text-color-hover: #F6FFF8;

    --select-color: #001a2c;
    --select-hover-color: #032531;
    --select-selected-color: #003D66;


    --header-element-color: #F6FFF8;
    --header-element-bg-color: transparent;
    --header-element-color-hover: #F6FFF8;
    --header-element-bg-color-hover: #032531;
    --header-element-color-selected: #F6FFF8;
    --header-element-bg-color-selected: #003D66;

    --card-color: #001a2c;
    --card-border-color: null;
    --card-hover-color: #032531;

    --icon-color: #F6FFF8;
    --icon-hover-color: #bfc0c0;
}

body {
    --success-color: #28a745;
    --button-success-color: #28a745;
    --button-success-color-hover: #2eb94e;
    --button-success-text-color: #F6FFF8;
    --button-success-text-color-hover: #F6FFF8;

    --warning-color: #ffc107;
    --button-warning-color: #ffc107;
    --button-warning-color-hover: #cca324;
    --button-warning-text-color: #000C14;
    --button-warning-text-color-hover: #000C14;

    --button-error-color: #ff5f3a;
    --button-error-color-hover: #dc3545;
    --button-error-text-color: #F6FFF8;
    --button-error-text-color-hover: #F6FFF8;

    transition: all .2s;
    --header-font-family: 'Manrope', sans-serif;
    --main-font-family: 'Nunito', sans-serif;
    --code-font-family: 'Noto Sans Mono', monospace;;
    background-color: var(--background-color);
}

code {
    font-family: var(--code-font-family);
}

.app-body {
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    margin-left: 8vw;
    margin-right: 8vw;
}

h1 {
    line-height: 1em;
    margin-top: 0;
    font-size: xx-large;
    font-family: var(--header-font-family);
    color: var(--text-color);
}

h2 {
    margin-top: 0;
    line-height: 1.2em;
    font-size: x-large;
    font-family: var(--header-font-family);
    color: var(--text-color)
}

h3 {
    margin-top: 0;

    line-height: 1.2em;
    font-size: large;
    font-family: var(--header-font-family);
    color: var(--text-color)
}

.grey-window {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: grey;
}

a {
    text-decoration: none;
}

.no-wrap {
    white-space: nowrap;
}

.vertical-center {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100vh;
}

.horizontal-center {
    position: absolute;
    display: flex;
    width: 100vw;
    justify-content: center;
}

.block-vertical-center {
    display: flex;
    align-items: center;
}

.app-form {
    display: flex;
    padding: 2rem;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 5px;
}

.form-bg {
    background-color: var(--header-color);
}

.error-text {
    max-width: 500px;
    font-family: var(--main-font-family);
    font-size: 1em;
    margin-top: .4rem;
    line-height: 1em;
    text-align: left;
    width: 100%;
    color: var(--error-text-color)
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    box-sizing: border-box;
    outline: none;
    border: none;
    border-radius: 5px;
    line-height: 1em;
    font-family: var(--main-font-family);
    font-size: 1em;
    background-color: var(--button-color);
    color: var(--button-text-color);
    padding: 6px;
    transition: .2s all;
    min-height: 3rem;
}

.button:hover, .button:focus {
    background-color: var(--button-color-hover);
    color: var(--button-text-color-hover)
}

.mini-button {
    width: max-content;
    padding: 8px 25px;
    min-height: 2rem;
    height: max-content;
}

.success-button {
    background-color: var(--button-success-color) !important;
    color: var(--button-success-text-color) !important;
}

.success-button:hover, .success-button:focus {
    background-color: var(--button-success-color-hover) !important;
    color: var(--button-success-text-color-hover) !important;
}

.warning-button {
    background-color: var(--button-warning-color) !important;
    color: var(--button-warning-text-color) !important;
}

.warning-button:hover, .warning-button:focus {
    background-color: var(--button-warning-color-hover) !important;
    color: var(--button-warning-text-color-hover) !important;
}

.error-button {
    background-color: var(--button-error-color);
    color: var(--button-error-text-color);
}

.error-button:hover, .error-button:focus {
    background-color: var(--button-error-color-hover);
    color: var(--button-error-text-color-hover);
}

.warning-loading > * >  .circle {
    background-color: var(--background-color);
}

.warning-loading > * > .circle-1 {
    background-color: var(--background-color);
}

.settings-icon {
    width: 20px;
    height: 20px;
    fill: var(--header-element-color);
    transition: fill .2s, all .5s;
}

@media screen and (max-width: 600px) {
    .app-form {
        width: 90vw;
        box-sizing: border-box;
    }

    .app-body {
        margin-left: 5vw;
        margin-right: 5vw;
    }
}

@media screen and (min-width: 601px) and (max-width: 800px) {
    .app-form {
        width: 64vw;
    }
    .error-text {
        margin-left: 2fr;
    }
}

@media screen and (min-width: 801px) {
    .app-form {
        width: 64vw;
        max-width: calc(500px + 4rem);

    }
}
