.settings {
    margin-top: 1rem;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 3fr;
    gap: 2rem
}

.menu {
    box-sizing: border-box;
    border-radius: 5px;
    height: max-content;
    overflow: hidden;
    cursor: pointer;
}

.settings-block {
    width: 100%;
}

.settings-container {
    width: 100%;
}

.channel-settings-radio-container {
    font-family: var(--main-font-family);
    display: block;
}

.channel-settings-radio-container > * {
    margin-bottom: 8px;
}

.menu-tab {
    display: flex;
    align-items: center;
    line-height: 1.3em;
    padding: 15px;
    border-bottom: 1px solid var(--border-color);
    color: var(--text-color);
    font-family: var(--main-font-family);
    transition: background-color .2s;
    background-color: var(--menu-tab-color);
}


.menu-tab:hover {
    background-color: var(--menu-tab-hover-color);
}


.active {
    background-color: var(--menu-tab-selected-color);
}

.active:hover {
    background-color: var(--menu-tab-selected-color);
}


.menu-tab:last-child {
    border: none;
}

@media screen and (max-width: 600px) {
    .settings {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
    }

    .settings-without-menu {
        width: 100%;
    }

    .menu {
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
    }

    .menu-tab {
        border-right: 1px solid var(--border-color);
        border-bottom: none;
        justify-content: center;
        text-align: center;
    }

    .menu-tab:last-child {
        border: none;
    }
}

@media screen and (min-width: 601px) and (max-width: 800px) {
    .settings {
        grid-template-columns: 2fr 5fr;
    }

    .settings-without-menu {
        width: 60%;
    }
}

@media screen and (min-width: 801px) {
    .settings {
        grid-template-columns: 2fr 6fr;
    }

    .settings-without-menu {
        width: 60%;
        max-width: 800px;
    }
}

.menu-horizontal {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
}

.menu-tab-horizontal {
    border-right: 1px solid var(--border-color);
    border-bottom: none;
    text-align: center;
    justify-content: center;
}
